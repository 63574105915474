<template>
  <div class="managegroups innerpage">
    <Header></Header>

    <v-container class="my-3">
      <v-row>
        <v-col cols="12">
          <h1 class="text-h2">Manage Worker Groups</h1>
          <v-divider class="mt-8 mb-8"></v-divider>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card-title class="px-0">
            <h2 class="text-h3">My Organization Worker Groups</h2>
          </v-card-title>

          <v-data-table
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="org_workergroups"
            :disable-sort="true"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :footer-props="{
              itemsPerPageOptions: [10]
            }"
            class="elevation-5 align-center"
          >
            <template v-slot:top>
              <v-toolbar flat color="grey lighten-2">
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      dark
                      rounded
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left>mdi-plus</v-icon>Create New Worker Group
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <h3 class="text-h4">{{ formTitle }}</h3>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-form ref="form" v-model="valid" lazy-validation>
                              <v-select
                                filled
                                v-model="editedItem.selectedLocation"
                                :items="org_locations"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select Location"
                                :rules="[v => !!v || 'Location is required']"
                                required
                                return-object
                              ></v-select>
                              <v-select
                                filled
                                v-model="editedItem.selectedWGtype"
                                :items="org_wgtypes"
                                item-text="name"
                                :menu-props="{ maxHeight: '400' }"
                                label="Select Worker Group Type"
                                :rules="[v => !!v || 'Type is required']"
                                required
                                return-object
                              ></v-select>
                              <v-text-field
                                filled
                                v-model="editedItem.name"
                                label="Worker Group Name"
                                :rules="[v => !!v || 'Name is required']"
                                required
                              ></v-text-field>
                            </v-form>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">Cancel</v-btn>
                      <v-btn color="primary" text @click="save(editedItem)"
                        >Save Worker Group</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" persistent max-width="500px">
                  <v-card class="pa-6 text-center">
                    <h3 class="headline mb-3">
                      Are you sure you want to delete this worker group?
                    </h3>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        dark
                        rounded
                        text
                        @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary"
                        dark
                        rounded
                        @click="deleteItemConfirm"
                        >Confirm</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" right top>
            {{ alert }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="snackbar = false"
                >Close</v-btn
              >
            </template>
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// @ is an alias to /src
import LocationService from "@/services/location.service";
import WorkgroupService from "@/services/workergroup.service";
import WGtypeService from "@/services/wgtype.service";
import Header from "@/components/Header";
import TeamService from "@/services/team.service";

export default {
  components: {
    Header
  },

  data: () => ({
    dataLoading: true,
    options: {},
    dialog: false,
    dialogDelete: false,
    itemsTotal: 0,
    currentPage: 1,
    lastPage: null,
    snackbar: false,
    alert: "",
    valid: true,
    headers: [
      { text: "Location", align: "start", value: "locationName" },
      // { text: "Worker Group Type", align: "start", value: "wgtypeName" },
      { text: "Worker Group Name", align: "start", value: "name" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" }
    ],

    org_locations: [],
    org_workergroups: [],
    org_wgtypes: [],
    editedIndex: -1,
    editedItem: {
      location: [],
      name: "",
      location_id: [],
      selectedLocation: null,
      selectedWGtype: null
    },
    defaultItem: {
      location: [],
      name: "",
      location_id: []
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Add New Worker Group"
        : "Edit Worker Group";
    },
    currentUser() {
      return this.$store.state.auth.user;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      async handler() {
        const orgId = this.getOrgId();
        if (orgId && this.lastPage !== null) {
          this.getPagedWorkergroups();
        }
      },
      deep: true
    }
  },

  created() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }

    this.initialize();
  },

  mounted() {},

  methods: {
    showSnackbar(text) {
      this.alert = text;
      this.snackbar = true;
    },

    async initialize() {
      const responseLocation = await LocationService.find({});
      const responseWGtype = await WGtypeService.find({});
      this.org_wgtypes = responseWGtype.data.data.slice();
      this.org_locations = responseLocation.data.data.slice();
      this.getPagedWorkergroups();
    },

    async getPagedWorkergroups() {
      this.dataLoading = true;
      let workergroups = [];

      const { page, itemsPerPage } = this.options;

      const responseWorkgroup = await WorkgroupService.find({
        query: {
          page: page
        }
      });

      workergroups = responseWorkgroup.data.data.slice();
      this.itemsTotal = responseWorkgroup.data.meta.total;
      this.lastPage = responseWorkgroup.data.meta.last_page;

      if (itemsPerPage > 0) {
        workergroups = workergroups.slice(
          (this.currentPage - 1) * itemsPerPage,
          this.currentPage * itemsPerPage
        );
      }

      this.org_workergroups = workergroups;
      this.dataLoading = false;
      this.hydrateLocation();
      this.hydrateWGtype();
    },

    getOrgId() {
      const id = null;

      return this.currentUser.user.organizations &&
        this.currentUser.user.organizations.id
        ? this.currentUser.user.organizations.id
        : id;
    },

    editItem(item) {
      this.editedIndex = this.org_workergroups.indexOf(item);
      this.editedItem = item;
      this.editedItem.selectedLocation = this.org_locations.filter(value => {
        return value.id === this.editedItem.location_id;
      })[0];

      this.editedItem.selectedWGtype = this.org_wgtypes.filter(value => {
        return value.id === this.editedItem.wgtype_id;
      })[0];

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.org_workergroups.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const orgUsers = await TeamService.getOrgUsers({});
      let cancelDelete = false;
      let usersList = orgUsers.data.organization_users;
      usersList.forEach(e => {
        if (e.user_workergroups && e.user_workergroups.length > 0) {
          e.user_workergroups.forEach(wk => {
            if (wk.workergroup.id === this.editedItem.id) {
              cancelDelete = true;
            }
          });
        }
      });

      if (cancelDelete) {
        this.showSnackbar(this.editedItem.name + " is already in use!");
      } else {
        this.org_workergroups.splice(this.editedIndex, 1);
        await WorkgroupService.remove({
          id: this.editedItem.id
        });
        this.itemsTotal--;
        this.showSnackbar(this.editedItem.name + " has been deleted");
      }

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    hydrateLocation() {
      this.org_workergroups.forEach(wg => {
        const result = this.org_locations.filter(obj => {
          return obj.id === wg.location_id;
        });

        wg["locationName"] = result[0].name;
      });
    },

    hydrateWGtype() {
      this.org_workergroups.forEach(wg => {
        const result = this.org_wgtypes.filter(obj => {
          return obj.id === wg.wgtype_id;
        });

        wg["wgtypeName"] = result[0].name;
      });
    },

    async save(item) {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        if (this.editedIndex > -1) {
          const data = {
            organization_id: this.currentUser.user.organizations.id,
            location_id: item.selectedLocation.id,
            name: item.name,
            wgtype_id: item.selectedWGtype.id
          };

          const workergroupId = this.org_workergroups[this.editedIndex].id;
          await WorkgroupService.update({
            id: workergroupId,
            data: data
          });

          this.editedItem.wgtype_id = this.editedItem.selectedWGtype.id;
          this.editedItem.location_id = this.editedItem.selectedLocation.id;
          this.editedItem.wgtypeName = this.editedItem.selectedWGtype.name;
          this.editedItem.locationName = this.editedItem.selectedLocation.name;

          Object.assign(
            this.org_workergroups[this.editedIndex],
            this.editedItem
          );
          this.showSnackbar(item.name + " has been updated");
        } else {
          // create item
          const data = {
            // organization_id: this.currentUser.user.organizations.id,
            location_id: item.selectedLocation.id,
            name: item.name,
            wgtype_id: item.selectedWGtype.id
          };

          const response = await WorkgroupService.create({
            data: data
          });

          this.org_workergroups.push(response.data);
          this.itemsTotal++;
          this.hydrateLocation();
          this.hydrateWGtype();
          this.showSnackbar(item.name + " has been created");
        }
        this.close();
      }
    }
  }
};
</script>
